<template>
    <div class="wrap">
        <div class="logo">
            <img src="../assets/images/bentley_logo_a-c1@2x.png"  alt="" />
        </div>
        <div class="info-box">
            <div class="box-top">
                <div class="left">
                    <span>{{toTraditional(userInfo.surname)}}{{toTraditional(userInfo.firstName)}}</span>
                    <strong>{{userInfo.hubId}}</strong>
                </div>
                <div class="right">{{toTraditional(userInfo.jobRole)}}</div>
            </div>
            <div v-if="needDC()" class="box-bottom">
                未DC認證
            </div>
        </div>
        <div class="video-box" @click="trainingImgClick()">
            <img :src="`${aliyunOssUrl}/base/training/hk_learning_banner.jpg`"  alt="" />
        </div>
        <div v-if="userInfo.jobRole == 'BMC'" class="cal">
            <van-calendar type="single" :row-height=33 :safe-area-inset-bottom="true" :readonly="true" :show-confirm="false" :show-subtitle="false" :show-mark="false" color="rgba(0,50,32,0.7)" :show-title="false" :poppable="false"  ></van-calendar>
        </div>
        <div class="confirm-btn" v-if="needDC()" @click="enrollHandle">預約DC認證時間</div>
        <van-popup v-model="showPicker" round position="bottom">
            <van-picker @cancel="() => showPicker = false" @confirm="onConfirm" show-toolbar title="預約時間" :columns="columns"/>
        </van-popup>
        <div v-if="needDC()" class="text-bootom">
          <span>*DC：發展評估中心</span>
        </div>
    </div>
</template>
<script>
import {Landing} from '@/network'
import { Picker, Popup, Toast, Dialog } from 'vant';
import { Calendar } from 'vant';
import moment from 'moment';
import { groupBy } from 'lodash';
import { toTraditional } from '@/utils'

export default {
    data() {
        return {
            hubId: sessionStorage.getItem('hubId') || null,
            phone: sessionStorage.getItem('phone') || null,
            columns: [],
            showPicker: false,
            timeDate: null,
            userInfo: {},
            groupList: null,
            allowDC: null,
            enrollStartDate: null,
            enrollEndDate: null,
            isAppointment: false,
            strAppointmentDate: null,
            strAppointmentTime: null,
            aliyunOssUrl: process.env.VUE_APP_ALIYUN_OSS_URL,
            toTraditional
        }
    },
    async created() {
        console.log('afterForm')
        this.userInfo = await Landing.getUserInfo({phone: this.phone})
        // const {data} = await Landing.getValidDate({pageNum:1, pageSize:9999, jobRole: this.userInfo.jobRole})
        // ***
        // const {data} = await Landing.getValidDate({pageNum:1, pageSize:9999, searchCondition: 'phone'})
        // this.columns = this.generateColumns(data)
        // ***
        this.allowDC = '' + await Landing.getConfig({paramKey: 'hk_allow_dc'})
        this.enrollStartDate = await Landing.getConfig({paramKey: 'dc_enroll_start_date'})
        this.enrollEndDate = await Landing.getConfig({paramKey: 'dc_enroll_end_date'})
    },
    components: {
        vanPicker: Picker,
        vanPopup: Popup,
        vanCalendar: Calendar,
    },
    methods: {
        needDC() {
            //return (this.userInfo.jobRole == '銷售經理' || this.userInfo.jobRole == '銷售經理' || this.userInfo.jobRole == '售後服務經理' || this.userInfo.jobRole == '售後服務經理') ? true : false
            if (!this.isNull(this.allowDC) && this.allowDC.indexOf(this.hubId) >= 0) {
                return true
            }
            return false
        },
        async enrollHandle() {
            if (this.canAppoiintment(this.enrollStartDate, this.enrollEndDate)) {
                // ***+ 
                const {data} = await Landing.getGmValidDate({pageNum:1, pageSize:9999, jobRole: this.userInfo.jobRole+'_1'})
                this.columns = this.generateColumns(data)
                // ***+
                this.showPicker = true
            }
            // this.columns.forEach(o => {
            //     o.children.forEach(item =>{
            //         if (this.canAppoiintment(this.enrollStartDate, this.enrollEndDate)) {
            //             this.showPicker = true
            //         }
            //     })
            // })
            if (!this.showPicker) {
                Dialog.alert({
                    width: '95%',
                    allowHtml: true,
                    className: 'dialog-alert-font-class2',
                    messageAlign: 'left',
                    title: '非常抱歉',
                    message: '發展評估中心的預約僅在2月8日至2月10日開放。如有任何問題，請您與培訓助理進行聯繫：<br>bmc_trainingassistant@gpstrategies.com'
                })
            }
        },
        canAppoiintment(startDateStr, endDateStr) {
            let res = false
            if (startDateStr && endDateStr) {
                let startDate = new Date(startDateStr)
                let endDate = new Date(endDateStr)
                let now = new Date()
                if (now >= startDate && now < endDate) {
                    res = true
                }
            } else {
                res = true
            }
            return res
        },
        onConfirm(value) {
            /**
            this.timeDate = value;
            const data = this.groupList[value[0]].find(o => o.text == value[1])
            if (data.planCount - data.realCount == 0) {
              Toast('當前時間已約滿')
            } else {
              Landing.getReservationCount({
                phone: this.phone
              }).then((count) => {
                if (count >= 2) {
                  Dialog.alert({
                    width: '385px',
                    allowHtml: true,
                    className: 'dialog-alert-font-class2',
                    messageAlign: 'left',
                    message: '您符合DC報名條件，由於您所在經銷店該崗位名額已滿，您無法進行DC預約。<br>如有疑問，請您聯絡BMC培訓助理<br>BMC_trainingassistant@gpstrategies.com'
                  })
                } else {
                  Landing.addReservation({
                      hubId: this.hubId,
                      phone: this.phone,
                      appointmentDate: data.appointmentDate,
                      appointmentNo: data.appointmentNo,
                      appointmentTime: data.appointmentTime,
                      area: 'hk'
                  }).then(() => {
                      Toast('預約成功')
                      this.showPicker = false;
                      this.$router.replace({name: 'Finish'})
                  }).catch(e => {
                      console.warn('response', e.response)
                      Toast('預約失敗')
                  })
                }
              })
            }
             */
             this.strAppointmentDate = value[0]
            this.strAppointmentTime = value[1]
            const data = this.groupList[value[0]].find(o => o.text == value[1])
            Landing.addGmAppointment({
                hubId: this.hubId,
                phone: this.phone,
                appointmentDate: data.appointmentDate,
                appointmentNo: data.appointmentNo,
                appointmentTime: data.appointmentTime,
            }).then(() => {
                // Toast('預約成功')
                Landing.addReservation({
                    hubId: this.hubId,
                    phone: this.phone,
                    appointmentDate: data.appointmentDate,
                    appointmentNo: data.appointmentNo,
                    appointmentTime: data.appointmentTime,
                    area: 'hk'
                }).then(() => {
                    //Toast('預約成功')
                    //this.showPicker = false;
                    //this.$router.replace({name: 'Finish'})
                    Dialog.alert({
                        width: '95%',
                        allowHtml: true,
                        className: 'dialog-alert-font-class2',
                        messageAlign: 'left',
                        title: '預約成功',
                        message: '您的DC預約已成功。如有任何問題，請您與培訓助理進行聯系：<br>bmc_trainingassistant@gpstrategies.com'
                    })
                    this.showPicker = false
                    this.isAppointment = true
                    //this.$router.replace({name: 'Enrollment'})
                    this.$router.push({name: 'Enrollment', params: {phone: this.phone}})
                }).catch(e => {
                    console.warn('response', e.response)
                    //Toast('預約失敗')
                })


                
                
            }).catch(e => {
                console.warn('response', e.response)
                Toast('預約失敗')
            })
        },
        generateColumns(list) {
            this.groupList = groupBy(list.map(o => {
                return {
                    ...o,
                    dateText: moment(o.appointmentDate).format('YYYY-MM-DD'),
                    disabled: o.planCount - o.realCount <= 0,
                    text: `${o.appointmentTime} ${o.planCount - o.realCount <= 0 ? '已滿':''}`
                }
            }), 'dateText')
            return Object.keys(this.groupList).map(k => {
                return {
                    text: k,
                    children: this.groupList[k]
                }
            })
        },
        trainingImgClick() {
            this.$router.push({name: 'TrainingList'})
        },
        isNull(val){
            return val === null || val === void 0 || val === '' || (val).toString() === 'NaN'
        }
    }
}
</script>

<style lang="less">
.dialog-alert-font-class2 {
  font-family: 'Bentley', 'simhei', RegularEng, Helvetica, 'Avenir', Arial, sans-serif !important;
  font-size: 12px !important;
  @dialog-width: 400px;
  @dialog-font-size: @font-size-sm;
}
.van-calendar__selected-day {
    width: 100% !important;
    color: #fff;
    font-size: 14px;
    border-radius: 8px;
    background-color: #b7b6ba !important;
}
</style>

<style lang="scss" scoped>
.logo {
    width: 375px;
    height: 80px;
    background-color: white;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    img {
        width: 84px;
        height: 42px;
        margin: 20px 20px;
    }
}
.info-box {
    width: 100%;
    height: 125px;
    box-sizing: border-box;
    padding: 0 25px;
    background-color: rgba(0,50,32,0.7);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    // margin-bottom: 10px;
    .box-top {
        width: 100%;
        height: 70px;
        box-sizing: border-box;
        padding-bottom: 8px;
        border-bottom: 2px solid #fff;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 12px;
        .left {
            width: auto;
            height: auto;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            span {
                font-size: 16px;
                margin-right: 20px;
            }
            strong {
                font-weight: 400;
                font-size: 12px;
            }
        }
        .right {
            font-size: 16px;
        }
    }
    .box-bottom {
        width: 100%;
        height: auto;
        color: #fff;
        font-size: 16px;
    }
}
.video-box {
    display: flex;
    justify-content: center;
    padding: 10px 0px 10px;
    background-color: #efeef3;
    img {
        width: 100%;
        max-height: 160px;
        // border-radius: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
.confirm-btn {
    width: 325px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,50,32,0.7);
    border-radius: 4px;
    font-size: 16px;
    color: #fff;
    margin: 0 auto;
}
.text-bootom {
  margin: 35px auto auto 20px;
//   position: fixed;
  font-size: 12px;
}
.cal {
    height: 220px;
    margin: 0px 10px 10px;
}
</style>